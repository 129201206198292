<template>
  <div class="footerContainer">
    <div class="nav">
      <div class="nav__company-info">
        <img :src="companyInfo.qrcCode" alt="" class="nav__qrc-code" />
        <img :src="companyInfo.ydlQrcCode" alt="" class="nav__qrc-code" />
        <div class="nav__phone">
          <img src="../assets/homePhone.jpg" alt="" /> {{ companyInfo.phone }}
        </div>
        <div class="nav__address">
          <img src="../assets/homeAddress.jpg" alt="" />{{
            companyInfo.address
          }}
        </div>
      </div>
      <div class="info">
        <ul class="info__list" v-for="item in menus" :key="item.id">
          <li class="info__title" @click="jump(item)">
            {{ item.name }}
          </li>
          <li class="info__item" v-for="child in item.child" :key="child.name" @click="jump(child)">
            {{ child.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <div>{{ companyInfo.copyright }}</div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
export default {
  name: 'Footer',
  props: {},
  data() {
    return {}
  },
  methods: {
    jump(item) {
      const { path, disable } = item
      if (disable === 1) {
        return
      }
      const urlArr = path.split('/')
      this.$store.commit('setActiveItem', item)
      this.$store.commit('setNavUrl', `/${urlArr[1]}`)
      sessionStorage.setItem('NAVURL', `/${urlArr[1]}`)
      sessionStorage.setItem('ACTIVEITEM', JSON.stringify(item))
      router.push(path)
    },
  },
  computed: {
    menus() {
      return this.$store.state.menus
    },
    companyInfo() {
      return this.$store.state.companyInfo
    },
  },
}
</script>

<style scoped lang="less">
.footerContainer {
  background: #181e2f;
}
.nav {
  // padding: 1.12rem 1.56rem 0 1.56rem;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 1.12rem 0.8rem 0;
  .nav__company-info {
    color: #fff;
    .nav__qrc-code {
      width: 1.33rem;
      height: 1.33rem;
      margin-right: 10px;
    }
    .nav__phone {
      font-size: 0.32rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #ffffff;
      margin-top: 0.15rem;
    }
    .nav__address {
      font-size: 0.16rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #e8e9eb;
      line-height: 0.3rem;
      img {
        margin-right: 0.1rem;
      }
    }
    img {
      width: 0.2rem;
      height: 0.2rem;
      vertical-align: middle;
    }
  }
  .info {
    display: flex;
    .info__list {
      margin-right: 0.7rem;
      font-size: 0.21rem;
      font-family: PingFang SC-Medium, PingFang SC;
      color: #e8e9eb;
      line-height: 0.25rem;
    }
    .info__title {
      margin-bottom: 0.4rem;
      cursor: pointer;
    }
    .info__item {
      margin-top: 0.2rem;
      font-size: 0.17rem;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #e8e9eb;
      cursor: pointer;
    }
  }
}
.copyright {
  width: 100%;
  border-top: 1px solid #d9d9d9;
  margin-top: 1.48rem;
  text-align: center;
  font-size: 0.16rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #e8e9eb;
  padding: 0.4rem 0;
}
</style>
