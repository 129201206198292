<template>
  <div class="tips">
    <div class="tips__watch">
      <img class="tips__img" src="@/assets/home/watch.png" alt="" />
      <img class="tips__qrcCode" :src="companyInfo.qrcCode" alt="" />
    </div>
    <div class="tips__phone">
      <img class="tips__phone__img" src="@/assets/home/phone.png" alt="" />
      <div class="tips__info">{{ companyInfo.phone }}</div>
    </div>
    <div class="placeholder"></div>
    <div class="tips__us" @click="jump">
      <img class="tips__us__img" src="@/assets/home/us.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    companyInfo() {
      return this.$store.state.companyInfo
    },
  },
  methods: {
    jump() {
      this.$router.push('/contactUs')
    },
  },
}
</script>

<style lang="less" scoped>
.tips {
  position: fixed;
  right: 0;
  bottom: 40%;
  z-index: 99;
  .tips__watch {
    cursor: pointer;
    padding: 0.13rem;
    // background: #f3a736;
    background: #8b8e96;
    text-align: center;
    border-radius: 0.05rem 0.05rem 0 0;
    border-bottom: 1px dashed #fff;
    .tips__img {
      width: 0.29rem;
    }
    .tips__qrcCode {
      display: none;
      width: 1.24rem;
      height: 1.23rem;
    }
    &:hover {
      .tips__img {
        display: none;
      }
      .tips__qrcCode {
        display: block;
      }
    }
  }
  .tips__phone {
    position: absolute;
    right: 0;
    cursor: pointer;
    // background: #f3a736;
    background: #8b8e96;
    padding: 0.13rem;
    text-align: center;
    border-bottom: 1px dashed #fff;
    z-index: 10;
    .tips__phone__img {
      width: 0.29rem;
    }
    .tips__info {
      display: none;
      width: 1.5rem;
      padding: 0.06rem;
      color: #fff;
      font-size: 0.2rem;
    }
    &:hover {
      .tips__phone__img {
        display: none;
      }
      .tips__info {
        display: block;
      }
    }
  }
  .placeholder {
    height: 0.59rem;
  }
  .tips__us {
    position: absolute;
    right: 0;
    cursor: pointer;
    // background: #f3a736;
    background: #8b8e96;
    padding: 0.13rem;
    text-align: center;
    z-index: 9;
    border-radius: 0 0 0.05rem 0.05rem;
    .tips__us__img {
      width: 0.29rem;
    }
  }
}
</style>